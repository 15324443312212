<template>
  <v-card outlined>
    <v-card-text>
      <v-data-table
        flat 
        disable-sort
        class="mb-3 title" 
        hide-default-footer
        loader-height="2"
        :loading="loading"
        :headers="headers" 
        :items="jurisdictions.data"
      >
        <template v-slot:[`item.boundary`]="{ item }">
          {{ item.boundary.name }}
        </template>

        <template v-slot:[`item.level`]="{ item }">
          Level {{ item.boundary.level }}
        </template>
        
        <template v-slot:[`item.country`]="{ item }">
          {{ item.boundary.country.name }}
        </template>

        <template v-slot:[`item.parent`]="{ item }">
          {{ item.parent ? item.parent.name : 'n/a' }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            dark
            label
            small
            color="primary"
            class="caption ttn"
            @click="$emit('edit', item)"
          >
            Edit  
          </v-btn> 
        </template>
      </v-data-table>

      <app-pagination
        v-if="jurisdictions.meta"
        :meta="jurisdictions.meta"
        @pageChanged="pageChanged"
      ></app-pagination>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      loading: true,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Level', value: 'level' },
        { text: 'Country', value: 'country' },
        { text: 'Boundary', value: 'boundary' },
        { text: 'Parent', value: 'parent' },
        // { text: 'Actions', value: 'actions' },
      ],
    }
  },

  computed: {
    ...mapGetters({
      jurisdictions: 'getJurisdictions'
    })
  },

  methods: {
    ...mapActions([
      'setJurisdictions'
    ]),

    pageChanged (page) {
      this.page = page
      this.setJurisdictions()
    },
  },

  mounted () {
    this.setJurisdictions().then(() => {
      this.loading = false
    })
  }
}
</script>
